<script>
import Layout from '@/views/layouts/main'
import { mask } from 'vue-the-mask'
import { onMounted, ref } from 'vue'
import { getProfile, updateProfile } from './api'
import Notify from '@/notify'

export default {
  setup () {
    const store = ref()
    const myAccount = ref({
      name: '',
      whatsapp: '',
      email: '',
      username: '',
      password: '',
      password_confirmation: ''
    })

    onMounted(() => {
      getMyAccount()
    })

    async function getMyAccount () {
      try {
        const response = await getProfile()
        myAccount.value = response.user
      } catch (error) {
        Notify.create({
          title: 'Falha ao carregar',
          timer: 1500
        })
      }
    }

    async function submitMyAccount () {
      try {
        if (myAccount.value.password !== myAccount.value.password_confirmation) {
          Notify.create({
            title: 'Por favor verifique sua senha!',
            timer: 2500
          })
          return
        } else {
          await updateProfile(myAccount.value)

          Notify.create({
            title: 'Perfil atualizado com sucesso!',
            timer: 2000
          })
        }
      } catch (e) {
        Notify.create({
          title: 'Ocorreu um erro ao atualizar as informações',
          timer: 1500
        })
      }
    }

    return {
      myAccount,
      store,
      getMyAccount,
      submitMyAccount
    }
  },
  components: {
    Layout
  },
  directives: {
    mask
  }
}

</script>

<template>
  <Layout :pagetitle="'Minha conta'">
    <div class="row">
      <div class="col-xl-6">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">
              Informações gerais
            </h4>
          </div>
          <div class="card-body">
            <form
              class="was-validated"
              @submit.prevent="submitMyAccount"
            >
              <div class="row">
                <div class="col-md-6">
                  <div class="mb-3">
                    <label
                      for="name"
                      class="form-label"
                    >Nome completo</label>
                    <input
                      id="name"
                      v-model="myAccount.name"
                      class="form-control"
                      placeholder="First name"
                      required
                    >
                    <div class="invalid-tooltip">
                      Campo obrigatório!
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="mb-3">
                    <label
                      for="WhatsApp"
                      class="form-label"
                    >WhatsApp</label>
                    <input
                      id="WhatsApp"
                      v-model="myAccount.whatsapp"
                      class="form-control"
                      placeholder="WhatsApp"
                      required
                    >
                    <div class="invalid-tooltip">
                      Campo obrigatório!
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="mb-3">
                    <label
                      for="userName"
                      class="form-label"
                    >Nome de vendedor</label>
                    <input
                      id="userName"
                      v-model="myAccount.username"
                      class="form-control"
                      placeholder="Nome do vendedor"
                      required
                    >
                    <div class="invalid-tooltip">
                      Campo obrigatório!
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="mb-3">
                    <label
                      for="email"
                      class="form-label"
                    >E-mail</label>
                    <input
                      id="email"
                      v-model="myAccount.email"
                      type="email"
                      class="form-control"
                      placeholder="E-mail"
                      required
                    >
                    <div class="invalid-tooltip">
                      Campo obrigatório!
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="mb-3">
                    <label
                      for="password"
                      class="form-label"
                    >Senha</label>
                    <input
                      id="password"
                      v-model="myAccount.password"
                      type="password"
                      class="form-control"
                    >
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="mb-3">
                    <label
                      for="passwordConfirm"
                      class="form-label"
                    >Confirmar senha</label>
                    <input
                      id="passwordConfirm"
                      v-model="myAccount.password_confirmation"
                      type="password"
                      class="form-control"
                    >
                  </div>
                </div>
              </div>
              <div class="d-flex flex-wrap align-items-center justify-content-end">
                <button
                  class="btn btn-primary"
                  type="submit"
                >
                  Salvar alterações
                </button>
              </div>
            </form>
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>
